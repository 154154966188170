import React from "react";
import { ComponentContainer } from "../styled.js";
import { ConcertsBg } from "../styled.js";

const Concerts = () => {
  return (
    <div id="live">
      <ConcertsBg>
        <ComponentContainer>
          <br />
          <br />
          <h2>Live</h2>
          <table>
            <tbody>
              <tr>
                <td>17.1.2025</td>
                <td>
                  <a href='https://www.facebook.com/events/528087346482273'>Playhouse Bar</a>
                </td>
                <td>
                  Helsinki
                </td>
              </tr>
              <tr>
                <td>26.3.2024</td>
                <td>
                  Playhouse Bar
                </td>
                <td>
                  Helsinki
                </td>
              </tr>
              <tr>
                <td>10.2.2024</td>
                <td>
                  Kalasataman vapaakaupunki
                </td>
                <td>
                  Helsinki
                </td>
              </tr>

              <tr>
                <td>28.10.2023</td>
                <td>
                  Oluthuone Paasikivi
                </td>
                <td>
                  Kerava
                </td>
              </tr>

              <tr>
                <td>26.11.2022</td>
                <td>
                  Mascot Bar
                </td>
                <td>
                  Helsinki
                </td>
              </tr>

              <tr>
                <td>17.10.2022</td>
                <td>
                  Vantaan Asunnottomien yö
                </td>
                <td>
                  Vantaa (Tikkurilan toriaukio)
                </td>
              </tr>

              <tr>
                <td>04.12.2021</td>
                <td>
                  Oranssi, Private party
                </td>
                <td>
                  Helsinki
                </td>
              </tr>
              <tr>
                <td>25.08.2021</td>
                <td>
                  VadelmaGaala 2021
                </td>
                <td>
                  Helsinki (City Museum)
                </td>
              </tr>
              <tr>
                <td>03.08.2020</td>
                <td>
                  Hanila Open Air
                </td>
                <td>
                  Estonia
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <div className="video-container">
            <div className="video-row">
              <iframe
                className="video-frame"
                src="https://www.youtube.com/embed/q6zEcGZfNwY"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </ComponentContainer>
      </ConcertsBg>
    </div>
  );
};

export default Concerts;
